import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, TextField, Button, Checkbox, FormControlLabel, 
  List, ListItem, ListItemText, Paper, Grid, Snackbar, Alert, Box
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function AdminPanel() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [stats, setStats] = useState({ total: 0, reviewed: 0 });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/admin/users`, {
        auth: {
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password')
        }
      });
      setUsers(response.data.users);
      setStats({
        total: response.data.total_samples,
        reviewed: response.data.total_reviewed
      });
    } catch (error) {
      console.error('Error fetching users:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Failed to fetch users. Please check your admin privileges.', severity: 'error' });
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/api/admin/create-user`, 
        {
          username: newUsername,
          password: newPassword,
          is_admin: isAdmin
        },
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
          }
        }
      );
      setNewUsername('');
      setNewPassword('');
      setIsAdmin(false);
      fetchUsers();
      setSnackbar({ open: true, message: 'User created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating user:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Failed to create user. Please try again.', severity: 'error' });
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1">
          Admin Panel
        </Typography>
      </Box>
      
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Overall Progress
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">
              Total Samples: {stats.total}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              Reviewed Samples: {stats.reviewed}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Create New User
        </Typography>
        <form onSubmit={handleCreateUser}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  />
                }
                label="Is Admin"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Create User
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Typography variant="h5" component="h2" gutterBottom>
        Existing Users
      </Typography>
      {users.length > 0 ? (
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              <ListItemText
                primary={`${user.username} (${user.is_admin ? 'Admin' : 'User'})`}
                secondary={
                  <Typography component="span" variant="body2" color="textSecondary">
                    Reviewed: {user.reviewed_samples}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No users found.</Typography>
      )}

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminPanel;
