import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import { getAuthHeader } from '../utils/auth';
import './LoginPage.css'; // We'll create this CSS file

function LoginPage({ setIsLoggedIn, setIsAdmin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // First, attempt to authenticate
      const response = await axios.get(`${API_URL}/api/test-auth`, {
        auth: { username, password }
      });
      
      if (response.status === 200) {
        // If authentication is successful, store credentials
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        setIsLoggedIn(true);
        
        // Check if user is admin
        const userResponse = await axios.get(`${API_URL}/api/user-info`, getAuthHeader());
        setIsAdmin(userResponse.data.is_admin);
        
        navigate('/');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        <input 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          placeholder="Username" 
          className="login-input"
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
