import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const styles = {
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.75, 1.5),
      fontSize: '0.8rem',
    },
  },
  textField: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
};

export default styles;