import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Container, Typography, List, ListItem, ListItemText, Box, Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthHeader } from '../utils/auth';

function ReviewPage() {
  const [reviewedSamples, setReviewedSamples] = useState([]);
  const [userStats, setUserStats] = useState({ total_samples: 0, reviewed_samples: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const requestMade = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (requestMade.current) {
      return;
    }

    const fetchData = async () => {
      try {
        requestMade.current = true;
        console.log('Making API calls...');
        const [samplesResponse, statsResponse] = await Promise.all([
          axios.get(`${API_URL}/api/reviewed-samples`, getAuthHeader()),
          axios.get(`${API_URL}/api/user-stats`, getAuthHeader())
        ]);
        
        console.log('Stats response:', statsResponse.data);
        setReviewedSamples(samplesResponse.data);
        setUserStats(statsResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleSampleClick = (sampleId) => {
    navigate(`/?clipId=${sampleId}`);
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Reviewed Samples
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" onClick={() => navigate('/')}>
          Back to Transcription Corrector
        </Button>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          Total Samples: {userStats.total_samples}
        </Typography>
        <Typography variant="h6">
          Reviewed Samples: {userStats.reviewed_samples}
        </Typography>
      </Box>
      {reviewedSamples.length === 0 ? (
        <Typography>No reviewed samples found.</Typography>
      ) : (
        <List>
          {reviewedSamples.map((sample) => (
            <ListItem 
              key={sample.id} 
              button 
              onClick={() => handleSampleClick(sample.id)}
              sx={{ 
                border: '1px solid #ddd', 
                borderRadius: '4px', 
                mb: 1,
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            >
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1">{sample.audio_filename}</Typography>
                    {sample.dont_include && (
                      <Chip 
                        label="Don't Include" 
                        color="error" 
                        size="small" 
                        sx={{ ml: 1 }}
                      />
                    )}
                    {sample.skipped && (
                      <Chip 
                        label="Skipped" 
                        color="warning" 
                        size="small" 
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      {sample.final_transcription || "No transcription"}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="text.secondary">
                      Verified at: {new Date(sample.verified_at).toLocaleString('en-IN', { 
                        timeZone: 'Asia/Kolkata',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}

export default ReviewPage;
