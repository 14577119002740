import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TranscriptionCorrector from './components/TranscriptionCorrector';
import ReviewPage from './components/ReviewPage';
import LoginPage from './components/LoginPage';
import AdminPanel from './components/AdminPanel';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} setIsAdmin={setIsAdmin} />} />
        <Route 
          path="/" 
          element={isLoggedIn ? <TranscriptionCorrector setIsLoggedIn={setIsLoggedIn} isAdmin={isAdmin} /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/review" 
          element={isLoggedIn ? <ReviewPage /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin" 
          element={isLoggedIn && isAdmin ? <AdminPanel /> : <Navigate to="/" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
