import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, TextField, Stack, Box, Alert, Link, Snackbar, CircularProgress, Backdrop } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/styles';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { API_URL } from '../config';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAuthHeader } from '../utils/auth';
import './TranscriptionCorrector.css'; // We'll create this CSS file

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledAudioPlayer = styled('audio')({
  width: '100%',
  marginBottom: 16,
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  marginBottom: theme.spacing(6),
}));

const formatYouTubeLink = (link, timestamp) => {
  if (!link || !timestamp) return link;
  
  const match = timestamp.match(/Start: (\d+):(\d+):(\d+)/);
  if (match) {
    const [, hours, minutes, seconds] = match;
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    
    const url = new URL(link);
    url.searchParams.set('t', totalSeconds);
    return url.toString();
  }
  
  return link;
};

const formatTimestamp = (timestamp) => {
  const match = timestamp.match(/Start: (\d+):(\d+):(\d+)\.(\d+), End: (\d+):(\d+):(\d+)\.(\d+)/);
  if (match) {
    const [, startHour, startMin, startSec, , endHour, endMin, endSec] = match;
    return `${startMin}:${startSec} - ${endMin}:${endSec}`;
  }
  return timestamp; // Return original if it doesn't match expected format
};

function TranscriptionCorrector({ setIsLoggedIn, isAdmin }) {
  const [audioSrc, setAudioSrc] = useState('');
  const [whisperTranscription, setWhisperTranscription] = useState('');
  const [aiSuggestion, setAiSuggestion] = useState('');
  const [finalTranscription, setFinalTranscription] = useState('');
  const [currentClipId, setCurrentClipId] = useState(null);
  const [currentFilename, setCurrentFilename] = useState('');
  const [medTerms, setMedTerms] = useState('');
  const [qualityCheck, setQualityCheck] = useState('');
  const [needsHumanReview, setNeedsHumanReview] = useState(false);
  const [noClipsMessage, setNoClipsMessage] = useState('');
  const [activeClipId, setActiveClipId] = useState(null);
  const [isEditable, setIsEditable] = useState(true);  // Always set to true initially
  const [humanVerified, setHumanVerified] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [ytLink, setYtLink] = useState('');
  const [timestamps, setTimestamps] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [dontInclude, setDontInclude] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const initialLoadRef = useRef(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isReviewable, setIsReviewable] = useState(true);

  const [originalClipId, setOriginalClipId] = useState(null);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (initialLoadRef.current) {
      return;
    }

    const loadInitialClip = async () => {
      const params = new URLSearchParams(window.location.search);
      const clipId = params.get('clipId');
      
      if (clipId) {
        console.log('Loading specific clip:', clipId);
        await loadClip(clipId);
      } else {
        console.log('Loading next clip');
        initialLoadRef.current = true;
        await loadNextClip();
      }
    };

    loadInitialClip();
  }, []);  // Empty dependency array

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    if (!username || !password) {
      console.error("Authentication credentials not found in localStorage");
      // Handle this case, maybe redirect to login
    } else {
      console.log("Credentials found in localStorage");
    }
  }, []);

  const loadNextClip = async () => {
    try {
      console.log("Loading next clip");
      const response = await axios.get(`${API_URL}/api/next-clip-to-review`, getAuthHeader());
      console.log("Next clip response:", response.data);
      if (response.data.id) {
        const clipData = response.data;
        setActiveClipId(clipData.id);
        setOriginalClipId(clipData.id);
        setAudioSrc(`${API_URL}/audio/${clipData.audio_filename}`);
        setWhisperTranscription(clipData.whisperTranscription);
        setAiSuggestion(clipData.aiSuggestion);
        setFinalTranscription(clipData.finalTranscription || '');
        setMedTerms(clipData.medTerms);
        setQualityCheck(clipData.qualityCheck);
        setHumanVerified(clipData.humanVerified);
        setYtLink(clipData.ytLink);
        setTimestamps(clipData.timestamps);
        setDontInclude(clipData.dont_include);
        setCurrentFilename(clipData.audio_filename);
        setSkipped(clipData.skipped);
      } else {
        setNoClipsMessage(response.data.message || "No more clips to review.");
      }
    } catch (error) {
      console.error('Error loading next clip:', error);
      setSnackbarMessage("Error loading next clip. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const loadClip = async (clipId) => {
    try {
      console.log("Loading clip with ID:", clipId);
      const response = await axios.get(`${API_URL}/api/load-clip/${clipId}`, getAuthHeader());
      const clipData = response.data;
      console.log("Clip data received:", clipData);
      setActiveClipId(clipData.id);
      setAudioSrc(`${API_URL}/audio/${clipData.audio_filename}`);
      setWhisperTranscription(clipData.whisperTranscription);
      setAiSuggestion(clipData.aiSuggestion);
      setFinalTranscription(clipData.finalTranscription || '');
      setMedTerms(clipData.medTerms);
      setQualityCheck(clipData.qualityCheck);
      setHumanVerified(clipData.humanVerified);
      setYtLink(clipData.ytLink);
      setTimestamps(clipData.timestamps);
      setDontInclude(clipData.dont_include);
      setCurrentFilename(clipData.audio_filename);
      console.log("Current filename set to:", clipData.audio_filename);
      setSkipped(clipData.skipped);
    } catch (error) {
      console.error('Error loading clip:', error);
      setSnackbarMessage("Error loading clip. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      // Check if transcription is empty
      if (!finalTranscription.trim()) {
        setSnackbarMessage("Please enter a transcription before saving");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return; // Exit the function early
      }

      setIsProcessing(true);  // Start loading
      await saveTranscription();
      setSnackbarMessage("Transcription saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await loadNextClip();
    } catch (error) {
      console.error('Error saving transcription:', error);
      setSnackbarMessage("Error saving transcription");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);  // End loading
    }
  };

  const handleCopyToFinal = (text) => {
    setFinalTranscription(text);
  };

  const handleLoadAdjacentClip = async (direction) => {
    try {
        const response = await axios.get(
            `${API_URL}/api/get-adjacent-clip/${activeClipId}/${direction}`,
            getAuthHeader()
        );
        
        if (response.data.status === "found") {
            const clipData = response.data;
            setActiveClipId(clipData.id);
            setAudioSrc(`${API_URL}${clipData.audioUrl}`);
            setWhisperTranscription(clipData.whisperTranscription);
            setAiSuggestion(clipData.aiSuggestion);
            setFinalTranscription(clipData.finalTranscription || '');
            setMedTerms(clipData.medTerms);
            setQualityCheck(clipData.qualityCheck);
            setHumanVerified(clipData.humanVerified);
            setYtLink(clipData.ytLink);
            setTimestamps(clipData.timestamps);
            setDontInclude(clipData.dont_include);
            setCurrentFilename(clipData.audio_filename);
            setSkipped(clipData.skipped);
            setIsReviewable(clipData.is_reviewable);

            console.log("Setting audio source:", `${API_URL}${clipData.audioUrl}`);
        } else {
            setSnackbarMessage(response.data.message);
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
        }
    } catch (error) {
        console.error(`Error loading ${direction} clip:`, error);
        setSnackbarMessage(`Error loading ${direction} clip`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    }
  };

  const handleReset = () => {
    loadClip(activeClipId);  // Load the active clip instead of currentClipId
  };

  const handleDontInclude = async () => {
    try {
      setIsProcessing(true);  // Start loading
      await axios.post(`${API_URL}/api/dont-include/${activeClipId}`, {}, getAuthHeader());
      setDontInclude(true);
      setSnackbarMessage("Clip marked as 'Don't Include'");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await loadNextClip();
    } catch (error) {
      console.error('Error marking clip as dont include:', error);
      setSnackbarMessage("Error marking clip as 'Don't Include'");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);  // End loading
    }
  };

  const saveTranscription = async () => {
    try {
      console.log("Saving transcription for clip:", activeClipId);
      console.log("Transcription data:", { final_transcription: finalTranscription });
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      console.log("Using credentials:", { username, password: password ? "********" : "not set" });
      
      const response = await axios.post(
        `${API_URL}/api/save-transcription/${activeClipId}`,
        { final_transcription: finalTranscription },
        {
          auth: { username, password },
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          }
        }
      );
      
      console.log("Save response:", response.status, response.data);
      if (response.status === 401) {
        console.error("Authentication failed. Please check your credentials.");
        // Handle authentication failure (e.g., redirect to login)
        return;
      }
      if (finalTranscription.trim() !== '') {
        setSkipped(false);
      }
    } catch (error) {
      console.error("Error saving transcription:", error.response?.data || error.message);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error details:', error.response.data);
      }
      throw error; // Re-throw the error to be handled by the calling function
    }
  };

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    const audioElement = document.querySelector('audio');
    if (audioElement) {
      audioElement.playbackRate = speed;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    setIsLoggedIn(false);
    navigate('/login');
  };

  // const testAuth = async () => {
  //   try {
  //     const username = localStorage.getItem('username');
  //     const password = localStorage.getItem('password');
  //     console.log("Testing auth with credentials:", { username, password: password ? "********" : "not set" });
      
  //     const response = await axios.get(
  //       `${API_URL}/api/test-auth`,
  //       {
  //         auth: { username, password },
  //         validateStatus: function (status) {
  //           return status < 500; // Resolve only if the status code is less than 500
  //         }
  //       }
  //     );
      
  //     console.log("Auth test response:", response.status, response.data);
  //     if (response.status === 401) {
  //       console.error("Authentication test failed. Please check your credentials.");
  //     }
  //   } catch (error) {
  //     console.error("Error testing authentication:", error.response?.data || error.message);
  //   }
  // };

  const handleSkip = async () => {
    try {
      setIsProcessing(true);  // Start loading
      await axios.post(`${API_URL}/api/skip-transcription/${activeClipId}`, {}, getAuthHeader());
      setSkipped(true);
      setSnackbarMessage("Clip marked as skipped");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await loadNextClip();
    } catch (error) {
      console.error('Error marking clip as skipped:', error);
      setSnackbarMessage("Error marking clip as skipped");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);  // End loading
    }
  };

  // // Call this function in useEffect or create a button to trigger it
  // useEffect(() => {
  //   testAuth();
  // }, []);

  return (
    <StyledContainer sx={{ 
      ...styles.container, 
      mt: 4
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {/* <Typography variant="h4" component="h1">Transcription Corrector</Typography> */}
        <Box>
          {isAdmin && (
            <Button
              component={RouterLink}
              to="/admin"
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
            >
              Admin Panel
            </Button>
          )}
          <Button
            onClick={handleLogout}
            variant="contained"
            color="secondary"
          >
            Logout
          </Button>
        </Box>
      </Box>
      {noClipsMessage ? (
        <Alert severity="info" sx={{ mb: 2 }}>{noClipsMessage}</Alert>
      ) : (
        <Box sx={{ mb: 3 }}>
          {audioSrc ? (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {currentFilename ? currentFilename.split('.')[0] : 'No file loaded'}
                </Typography>
                {ytLink && (
                  <Link
                    href={formatYouTubeLink(ytLink, timestamps)}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<YouTubeIcon />}
                      sx={{ fontSize: '0.75rem', py: 0.5, mr: 1 }}
                    >
                      YouTube
                    </Button>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      {formatTimestamp(timestamps)}
                    </Typography>
                  </Link>
                )}
              </Box>
              <audio 
                controls 
                src={audioSrc} 
                style={{ width: '100%', marginBottom: '10px' }}
                onError={(e) => {
                  console.error("Audio error:", e);
                  console.error("Audio src:", audioSrc);
                  console.error("Error code:", e.target.error ? e.target.error.code : 'N/A');
                  console.error("Error message:", e.target.error ? e.target.error.message : 'N/A');
                }}
              >
                Your browser does not support the audio element.
                <p>Audio source: {audioSrc}</p>
              </audio>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, mb: 3 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>Speed:</Typography>
                {[0.5, 1, 1.5, 2].map((speed) => (
                  <Button
                    key={speed}
                    variant={playbackSpeed === speed ? "contained" : "outlined"}
                    size="small"
                    onClick={() => handleSpeedChange(speed)}
                    sx={{ mx: 0.5, minWidth: '40px' }}
                  >
                    {speed}x
                  </Button>
                ))}
              </Box>
            </Box>
          ) : (
            <p>No audio file loaded. Audio source: {audioSrc}</p>
          )}
          <Box>
            {/* Remove this Stack component and its contents */}
            {/* <Stack direction="row" spacing={3} justifyContent="center" sx={{ mb: 2 }}>  
              <Button 
                variant="outlined" 
                onClick={() => handleLoadAdjacentClip('previous')} 
                sx={{ ... }}
              >
                Previous Clip
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => loadClip(originalClipId)} 
                sx={{ ... }}
              >
                Current Clip
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => handleLoadAdjacentClip('next')} 
                sx={{ ... }}
              >
                Next Clip
              </Button>
            </Stack> */}
          </Box>
        </Box>
      )}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack flex={1} sx={{ mb: 4 }}>  
          <StyledTextField
            label="Whisper Transcription"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={whisperTranscription}
            disabled
            InputLabelProps={{ shrink: true }}
            sx={styles.textField}
          />
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => handleCopyToFinal(whisperTranscription)}
            sx={styles.button}
          >
            Copy to Final
          </StyledButton>
        </Stack>
        <Stack flex={1} sx={{ mb: 4 }}>  
          <StyledTextField
            label="AI Suggestion"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={aiSuggestion}
            disabled
            InputLabelProps={{ shrink: true }}
            sx={styles.textField}
          />
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => handleCopyToFinal(aiSuggestion)}
            sx={styles.button}
          >
            Copy to Final
          </StyledButton>
        </Stack>
      </Stack>

      <TextField
        label="Human Verified Transcription"
        multiline
        rows={3}
        fullWidth
        value={finalTranscription}
        onChange={(e) => setFinalTranscription(e.target.value)}
        // Always editable
        InputLabelProps={{
          shrink: true,
          sx: { fontSize: '1rem', fontWeight: 'bold' }
        }}
        InputProps={{
          style: { fontSize: '0.9rem' }
        }}
        sx={{ mb: 2, '& .MuiInputBase-root': { padding: '8px' } }}
      />
      
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2, 
        mt: 2 
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row', 
          justifyContent: 'space-between',
          gap: 2
        }}>
          <Button 
            variant="contained" 
            color="error"  // Always use error color
            onClick={handleDontInclude}
            disabled={!isReviewable}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Don't Include
          </Button>
          <Button 
            variant="contained" 
            color="warning"
            onClick={handleSkip}
            disabled={!isReviewable}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Skip
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={!isReviewable}
            fullWidth={isMobile}
            sx={{ 
              flex: 1,
              backgroundColor: 'green', 
              '&:hover': { backgroundColor: 'darkgreen' } 
            }}
          >
            Save
          </Button>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row', 
          justifyContent: 'space-between',
          gap: 2,
          mt: 2
        }}>
          <Button
            variant="contained"
            onClick={() => {
                console.log('Review button clicked');
                navigate('/review');
            }}
            fullWidth={isMobile}
            sx={{ flex: 1 }}
          >
            Review 
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
  
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        open={isProcessing}
      >
        <CircularProgress color="inherit" />
        <Typography>Loading next clip...</Typography>
      </Backdrop>
  
    </StyledContainer>
  );
}

export default TranscriptionCorrector;
