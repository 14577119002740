export const getAuthHeader = () => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return {
    auth: {
      username,
      password
    }
  };
};

