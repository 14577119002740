import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, TextField, Button, Checkbox, FormControlLabel, 
  List, ListItem, ListItemText, Paper, Grid, Snackbar, Alert, Box
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function AdminPanel() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [assignedPrefixes, setAssignedPrefixes] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/admin/users`, {
        auth: {
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password')
        }
      });
    //   console.log("Fetched users:", response.data);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Failed to fetch users. Please check your admin privileges.', severity: 'error' });
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/api/admin/create-user`, 
        {
          username: newUsername,
          password: newPassword,
          is_admin: isAdmin,
          assigned_files: assignedPrefixes.split(',').map(prefix => prefix.trim())
        },
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
          }
        }
      );
      setNewUsername('');
      setNewPassword('');
      setIsAdmin(false);
      setAssignedPrefixes('');
      fetchUsers();
      setSnackbar({ open: true, message: 'User created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating user:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Failed to create user. Please try again.', severity: 'error' });
    }
  };

  const handleUpdateUser = async (userId, newFiles) => {
    try {
      const filesToAssign = newFiles.split(',').map(file => file.trim()).filter(file => file !== '');
      console.log("Files to assign:", filesToAssign);

      const response = await axios.put(`${API_URL}/api/admin/update-user/${userId}`, 
        { assigned_files: filesToAssign },
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
          }
        }
      );
      console.log("Update response:", response.data);
      
      // Update the local state with the new data
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === userId 
          ? { 
              ...user, 
              assigned_files: response.data.assigned_files,
              total_samples: response.data.total_samples,
              reviewed_samples: response.data.reviewed_samples
            } 
          : user
      ));
      
      setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating user:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Failed to update user. Please try again.', severity: 'error' });
    }
  };

  const handleBack = () => {
    navigate('/');  // Navigate to the root path, which should be your TranscriptionCorrector
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1">
          Admin Panel
        </Typography>
      </Box>
      
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Create New User
        </Typography>
        <form onSubmit={handleCreateUser}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  />
                }
                label="Is Admin"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Assigned Files (comma-separated)"
                value={assignedPrefixes}
                onChange={(e) => setAssignedPrefixes(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Create User
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Typography variant="h5" component="h2" gutterBottom>
        Existing Users
      </Typography>
      {users.length > 0 ? (
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              <ListItemText
                primary={`${user.username} (${user.is_admin ? 'Admin' : 'User'})`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Assigned Files: {user.assigned_files.join(', ')}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textSecondary">
                      Total Samples: {user.total_samples}, Reviewed Samples: {user.reviewed_samples}
                    </Typography>
                  </>
                }
              />
              <TextField
                label="Assigned Files"
                variant="outlined"
                size="small"
                value={user.assigned_files.join(', ')}
                onChange={(e) => {
                  const newFiles = e.target.value;
                  setUsers(prevUsers => prevUsers.map(u => 
                    u.id === user.id ? { ...u, assigned_files: newFiles.split(',').map(f => f.trim()) } : u
                  ));
                }}
                sx={{ mr: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateUser(user.id, user.assigned_files.join(', '))}
              >
                Update
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No users found.</Typography>
      )}

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminPanel;
